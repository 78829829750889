









import { Component, Vue } from 'vue-property-decorator';
import Header from './components/Header.vue';

@Component({
  components: { Header }
})
export default class App extends Vue {

  created() {
    window.localStorage.debug = 'fs:*';
    // @ts-ignore
    if (!window.JSHINT) window.JSHINT = require('jshint').JSHINT


  }
}
