












import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

@Component
export default class Header extends Vue {
  @Getter('fingerprint') fp: any;

  copyFingerprint(e: any) {
    try {
      e.target.select();
      document.execCommand('copy');
      console.log('copy');
    } catch (err) {
      console.error(err);
    }
  }
}
