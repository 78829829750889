












































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';

@Component
export default class General extends Vue {

  @State('picker') picker: any;

  @Getter('picker/getConfigKey') getConfigKey: any;
  @Getter('picker/sources') sources: any;
  @Getter('picker/langs') langs: any;

  // eslint-disable-next-line
  @Mutation('picker/setConfigKey') setConfigKey: ({
    key,
    value,
  }: {
    key: string;
    value: any;
  }) => {};

  public getConfigValue(key: string) {
    return this.getConfigKey(key);
  }

  public setConfigValue(key: string, value: any) {
    return this.setConfigKey({ key, value });
  }

  public setConfigArray(key: string, value: string) {
    return this.setConfigKey({ key, value: value.split(',') });
  }

  public getConfigArray(key: string) {
    const val = this.getConfigKey(key);

    if (Array.isArray(val)) {
      return val.join(',');
    }

    return val;
  }
}
