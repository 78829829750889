
































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import General from './../components/picker/General.vue';
import Store from './../components/picker/Store.vue';
import Upload from './../components/picker/Upload.vue';
import DropPane from './../components/picker/DropPane.vue';

@Component({
  components: { General, Store, Upload, DropPane },
})
export default class PickerOptions extends Vue {
  @State('picker') picker: any;

  @Getter('picker/getConfigKey') getConfigKey: any;
  // eslint-disable-next-line
  @Mutation('picker/setConfigKey') setConfigKey: ({
    key,
    value,
  }: {
    key: string;
    value: string;
  }) => {};

  public getConfigValue(key: string) {
    return this.getConfigKey(key);
  }

  public setConfigValue(key: string, value: string) {
    console.log(key, value);
    return this.setConfigKey({ key, value });
  }

  public setSelectConfigValue(key: string, e: any) {
    if(e.target.options.selectedIndex > -1) {
      this.setConfigValue(key, e.target.options[e.target.options.selectedIndex].value);
    }
  }
}
