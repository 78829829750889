









































































































































import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import ConfigLoader from './../components/picker/ConfigLoader.vue';
import PickerOptions from './PickerOptions.vue';

@Component({
  components: {
    ConfigLoader,
    PickerOptions,
  },
})
export default class Home extends Vue {
  @Getter('clientConfig') clientConfig: any;
  @Getter('fingerprint') fp: any;

  @Mutation('setClientConfig') setClientConfig: any;

  setApikey(e: any) {
    this.setClientConfig({
      ...this.clientConfig,
      ...{
        apikey: e.target.value,
      },
    });
  }

  setPolicy(e: any) {
    this.setClientConfig({
      ...this.clientConfig,
      ...{
        security: {
          policy: e.target.value,
        },
      },
    });
  }

  setSignature(e: any) {
    this.setClientConfig({
      ...this.clientConfig,
      ...{
        security: {
          signature: e.target.value,
        },
      },
    });
  }

  setCname(e: any) {
    this.setClientConfig({
      ...this.clientConfig,
      ...{
        cname: e.target.value,
      },
    });
  }
}
